exports.widgets = [
   {
      widget_type: 'point-widget',
      reportName: 'totalPower',
      displayName: 'Tổng công suất',
      icon: 'mdi-alpha-a-circle',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'kW',
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 3,
      
   },
   {
      widget_type: 'point-widget',
      reportName: 'accuEnergy',
      displayName: 'Điện năng tiêu thụ',
      icon: 'mdi-alpha-a-circle',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'kWh',
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 3,
   },
   {
      widget_type: 'point-widget',
      reportName: 'frequency',
      displayName: 'Tần số',
      icon: 'mdi-alpha-a-circle',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'Hz',
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 3,
   },
   {
      widget_type: 'point-widget',
      reportName: 'copshi',
      displayName: 'Hệ số công suất',
      icon: 'mdi-alpha-a-circle',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: '',
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 3,
   },

   { widget_type: 'newline-widget', height: "1px" },
   
   {
      widget_type: 'point-widget',
      reportName: 'voltage_A',
      displayName: 'Điện áp pha A',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'V',
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'voltage_B',
      displayName: 'Điện áp pha B',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'V',
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'voltage_C',
      displayName: 'Điện áp pha C',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'V',
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'current_A',
      displayName: 'Dòng điện pha A',
      icon: 'mdi-alpha-p-circle-outline',
      value: 0,
      defaultValue: 0,
      color: '#795548',
      unit: 'A',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'current_B',
      displayName: 'Dòng điện pha B',
      icon: 'mdi-alpha-p-circle-outline',
      value: 0,
      defaultValue: 0,
      color: '#FF9800',
      unit: 'A',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'current_C',
      displayName: 'Dòng điện pha C',
      icon: 'mdi-alpha-p-circle-outline',
      value: 0,
      defaultValue: 0,
      color: '#03A9F4',
      unit: 'A',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   { widget_type: 'newline-widget', height: "1px" },
   {
      widget_type: 'chart-widget',
      title: 'Lịch sử dòng điện',
      chartType: 'line',
      points: [
         {
            reportName: 'current_A', name: 'Dòng điện pha A', accu: false, active: true,
            scale: 1, unit: 'A', color: "#795548", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'current_B', name: 'Dòng điện pha B', accu: false, active: true,
            scale: 1, unit: 'A', color: "#FF9800", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'current_C', name: 'Dòng điện pha C', accu: false, active: true,
            scale: 1, unit: 'A', color: "#03A9F4", backgroundColor: "#FF000000", 
         },
      ], 
      mobileCols: 12,
      laptopCols: 12,
      minHeight: "300px",
   },

]

exports.style = {
   backgroundImg: 'url("/imgs/bg0.jpg")',
   backgroundColor: '#AAAAAA',
   transparentColor: '#00000033',
   blurBackground: true,
}