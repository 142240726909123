<template>
   <v-card v-if="nextChildPage"
      dark flat tile 
      class="px-0 pl-0 py-0"
      style="background-color:transparent;height:100%;">
      <v-card-title v-if="$root.bottomNavigate=='goDownOnly'"

         class="px-5 py-0"
         style="height:32px;background-color:transparent;"
         @click="goDown"
         >
         <v-btn
            icon
            style="width:30px;height:30px;margin-top:-10px;">
            <v-icon style="font-size:24px;">mdi-arrow-down-bold</v-icon>
         </v-btn>

         <v-spacer/>

         <div style="display:inline-block;min-width:200px;height:44px;">
            <div
               style="height:34px;line-height:32px;font-size:20px;font-weight:700;">
               {{nextChildPage?nextChildPage.label:'Page Title'}}
            </div>
         </div>

         <v-spacer/>

         <v-btn
            icon
            style="width:30px;height:30px;margin-top:-10px;">
            <v-icon style="font-size:24px;">mdi-arrow-down-bold</v-icon>
         </v-btn>
      </v-card-title>

       <div v-if="$root.bottomNavigate=='tab' && currentPage && currentPage.childs"
         class="px-0 py-0"
         style="max-height:32px;background-color:transparent;"
         >
         <v-spacer/>
         <div class="hideScrollbar"
            style="scrollbar-width: none;max-height:32px;max-width:100%;white-space: nowrap;;overflow-x:auto;">
            <template v-for="(childPage, index) in currentPage.childs">
               <v-btn 
                  :color="childPage.path==currentChildPage.path?'green':'#757575'"
                  @click="gotoPage(childPage)"
                  :key="index"
                  style="margin: 3px 3px 3px 3px;"
                  class="text-none">
                  <b>{{childPage.label}}</b>
               </v-btn>
            </template>
         </div>
         <v-spacer/>
      </div>
   </v-card>
</template>

<script>
export default {
   props: ['pageTitle'],
   computed: {
      nextChildPage: function () {
         let childPage = null
         this.pages.forEach(page => {
            let nextIndex = -1
            if (page.childs && page.childs.length>1) {
               page.childs.forEach((child, index) => {
                  if (child.path == this.$route.fullPath) {
                     nextIndex = index + 1
                     if (nextIndex>= page.childs.length) {
                        nextIndex = 0
                     }
                  }
               })
            }
            if (nextIndex>=0) {
               childPage = page.childs[nextIndex]
            }
            
         })
         return childPage
      },
      currentChildPage: function () {
         let childPage = null
         this.pages.forEach(page => {
            let childIndex = -1
            if (page.childs && page.childs.length>1) {
               page.childs.forEach((child, index) => {
                  if (child.path == this.$route.fullPath) {
                     childIndex = index
                  }
               })
            }
            if (childIndex >= 0) {
               childPage = page.childs[childIndex]
            }
            
         })
         return childPage
      },
      currentPage: function () {
         let retPage = null
         let pageIndex = -1
         this.pages.forEach((page, pIndex) => {
            if (page.childs && page.childs.length>0) {
               page.childs.forEach(child => {
                  if (child.path == this.$route.fullPath) {
                     pageIndex = pIndex
                  }
               })
            } else {
               if (page.path == this.$route.fullPath) {
                  pageIndex = pIndex
               }
            }
         })
         if (pageIndex >= 0) {
            retPage = this.pages[pageIndex]
         }
         return retPage
      }
   },
   data () {
      return {
         activePageIndex: 0,
         pages: require('@/mkdata/pages').pages,
         allowBackButton: false,
         loopInfinity: true
      }
   },
   methods: {
      goDown () {
         // console.log(this.$route.fullPath)
         if (this.nextChildPage) {
            // console.log("Go to path " + this.nextChildPage.path)
            this.$router.push({path: this.nextChildPage.path})
         }
         /*
         this.pages.forEach(page => {
            let nextIndex = -1
            if (page.childs && page.childs.length>0) {
               page.childs.forEach((child, index) => {
                  if (child.path == this.$route.fullPath) {
                     nextIndex = index + 1
                     if (nextIndex>= page.childs.length) {
                        nextIndex = 0
                     }
                  }
               })
            }
            if (nextIndex >= 0) {
               console.log("Go to path " + page.childs[nextIndex].path)
               this.$router.push({path: page.childs[nextIndex].path})
            }
         })
         */
      },
      gotoPage (childPage) {
         if (childPage) {
            this.$router.push({path: childPage.path})
         }
      }
   }
}
</script>
   
<style>
   .hideScrollbar::-webkit-scrollbar {
      display: none;
   }

   /* Hide scrollbar for IE, Edge and Firefox */
   .hideScrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
   }
</style>