
function setupComponents(Vue) {
   Vue.component("position-layout", () => import('../components/templates/PositionLayout.vue'))
   Vue.component("stack-layout", () => import('../components/templates/StackLayout.vue'))
   Vue.component("report-layout", () => import('../components/templates/ReportLayout.vue'))
   Vue.component("event-layout", () => import('../components/templates/EventLayout.vue'))
   Vue.component("setting-layout", () => import('../components/templates/SettingLayout.vue'))
   
   Vue.component("point-widget", () => import('../components/widgets/PointWidget.vue'))
   Vue.component("spacer-widget", () => import('../components/widgets/SpacerWidget.vue'))
   Vue.component("newline-widget", () => import('../components/widgets/NewlineWidget.vue'))
   Vue.component("chart-widget", () => import('../components/widgets/ChartWidget.vue'))
   Vue.component("html-widget", () => import('../components/widgets/HtmlWidget.vue'))

   Vue.component("user-profile", () => import('../components/atom/UserProfile.vue'));
   Vue.component("user-editor", () => import('../components/atom/UserEditor.vue'));
   Vue.component("point-setter", () => import('../components/atom/PointSetter.vue'));
   Vue.component("date-picker", () => import('../components/atom/DatePicker.vue'))
   Vue.component("point-picker", () => import('../components/atom/PointPicker.vue'))
   Vue.component("line-chart-points", () => import('../components/atom/LineChartPoints.vue'))
}

export { setupComponents }