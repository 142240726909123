<template>
  <v-app>
    <div id="app" >

      <div
        :class="{
          'sys-info-bar': $vuetify.breakpoint.mdAndUp, 
          'sys-info-bar-mobile': $vuetify.breakpoint.smAndDown 
        }"
        >
        <point-setter v-model='setterDialog' :point="setterPoint"/>
        <pn-info-bar />
      </div>

      <div
        :class="{
        'sys-nav-bar': $vuetify.breakpoint.mdAndUp, 
        'sys-nav-bar-mobile': $vuetify.breakpoint.smAndDown 
        }">
        
        <pn-nav-bar 
          :pageTitle="activePageTitle"
          @pageChange="gotoPath" />
      </div>

      <div
        :class="{
        'sys-nav-bar-bottom': $vuetify.breakpoint.mdAndUp, 
        'sys-nav-bar-bottom-mobile': $vuetify.breakpoint.smAndDown 
        }">
        <pn-navbar-bottom/>
      </div>

      <div v-if="kbVisible && ($root.isElectron || $root.virtualKeyboard)"
        class="pt-2 pb-2 px-4 text-center"
        style="z-index:1000;position: absolute;bottom:0;left:0;right:0;background-color:#DDDDDD;">

        <vue-touch-keyboard
          style="margin:auto;"
          :style="{'max-width': kbLayout=='numeric'?'800px':'none'}"
          id="keyboard" 
          :options="kbOptions" :layout="kbLayout" 
          :cancel="kbHide" :accept="kbAccept" :next="kbNext"
          :input="kbInput"/>
      </div>

      <div id="content">
        <v-content style="width:100%;height:100%;">
          <router-view />
        </v-content>
      </div>
    </div>
  </v-app>
</template>

<script>
import PnNavBar from '@/components/core/PnNavBar.vue'
import PnInfoBar from '@/components/core/PnInfoBar.vue'
import PnNavBarBottom from '@/components/core/PnNavBarBottom.vue'
import { mapGetters } from "vuex"
export default {
    components: {
      'pn-nav-bar': PnNavBar,
      'pn-info-bar': PnInfoBar,
      'pn-navbar-bottom': PnNavBarBottom,
    },
    computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "getLevel",
        fullname: "getFullName",
        allowControl: "allowControl"
      }),
    },
    data () {
      return {
         activePageTitle: 'Home',
         setterPoint: {},
         setterDialog: false,
         // keyboard --------------
         kbOptions: {
            useKbEvents: false,
            preventClickEvent: false
         },
         kbLayout: "normal",
         kbVisible: false,
         kbInput: null
         // end keyboard ----------
      }
    },
    sockets: {
        connect: function () {
          // console.log("IO Connected")
          this.$socket.emit("registerIsHmi", {})
        },
    },
    methods: {
      gotoPath (path) {
        this.$router.push({path: path})
      },
      showPointControl (point) {
        if(this.allowControl) {
          this.setterPoint = point
          this.setterDialog = true
        } else {
          alert('Lỗi! Bạn chưa được cấp quyền điều khiển hệ thống!')
        }
        
      },
      // keyboard --------------------------
      kbHide() {
        this.kbVisible = false
      },
      kbAccept (text) {
        console.log(`Keyboard text ${text}`)
        this.kbHide()
      },
      kbNext() {
        let inputs = document.querySelectorAll("input");
        let found = false;
        [].forEach.call(inputs, (item, i) => {
          if (!found && item == this.input && i < inputs.length - 1) {
            found = true;
            this.$nextTick(() => {
              inputs[i+1].focus()
            });
          }
        });
        if (!found) {
          // this.kbInput.blur()
          this.kbHide()
        }
      },	
      requestShowKeyboard(input, layout) {
        this.kbInput = input
        this.kbLayout = layout
        this.kbVisible = true
      }
      // end keyboard ----------------------
    },
    async created() {
      const vm = this
      vm.$eventBus.$on('showPointSetter', this.showPointControl)
      vm.$eventBus.$on('requestShowKeyboard', this.requestShowKeyboard)

      if(!this.userId || !this.token) return
      try {
        let res = await this.axios.get(
            `${this.$root.apiUser}/user/${this.userId}?preload_fields_of_caller=role`, 
            { headers: { "x-token": this.token } }
            )
        let user = res.data.content
        if (user.roles && user.roles.length>0) {
          user.roles = user.roles.map(role => role.code)
        } else {
          user.roles = []
        }
        this.$store
          .dispatch("saveUserInfo", {
            fullname: user.fullname,
            userId: user._id,
            avatar: user.avatar,
            token: user.token,
            role: user.role,
            roles: user.roles,
            rememberMe: self.rememberMe,
          })
          .then(() => {
          });
      } catch (err) { console.log(err) }

    },
    destroyed() {
      const vm = this
      vm.$eventBus.$off('showPointSetter', this.showPointControl)
      vm.$eventBus.$off('requestShowKeyboard', this.requestShowKeyboard)
    }
}
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #666666;
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #EEEEEE;
    box-sizing: border-box;
    overflow: hidden;
  }

  html {
      overflow: scroll;
      overflow-x: hidden;
  }
  ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }
  ::-webkit-scrollbar-thumb {
      background: #CCCCCC;
  }

  .sys-info-bar {
    position: absolute;
    top: 6px;
    left: 6px;
    height: 48px;
    width: 68vw;
    min-width: 300px;
    max-width: 70vw;
    padding: 0px;
    margin: 0px;
    z-index: 9;
    border-radius: 6px 6px 6px 6px;
    background-color: #37474F;
  }

  .sys-info-bar-mobile {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 48px;
    width: 60vw;
    min-width: 100px;
    max-width: 60vw;
    padding: 0px;
    margin: 0px;
    z-index: 9;
    border-radius: 0;
    background-color: #37474F;
  }

  .cover-all-topmost {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 9;
  }

  .sys-nav-bar {
    position: absolute;
    top: 6px;
    right: 6px;
    height: 48px;
    width: 30vw;
    min-width: 300px;
    max-width: 60vw;
    padding: 0px;
    margin: 0px;
    z-index: 9;
    border-radius: 8px 8px 8px 8px;
    background-color: #37474F;
  }

  .sys-nav-bar-mobile {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 48px;
    width: 60vw;
    min-width: 180px;
    max-width: 60vw;
    padding: 0px;
    margin: 0px;
    z-index: 9;
    border-radius: 0;
    background-color: #37474F;
  }

  .sys-nav-bar-bottom {
    position: absolute;
    bottom: 1px;
    left: 5vw;
    right: 5vw;
    min-width: 300px;
    max-width: 100vw;
    padding: 0px;
    margin: 0px;
    z-index: 9;
    border-radius: 22px 22px 0px 0px;
    background-color: #00838F00;
  }
  .sys-nav-bar-bottom-mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    padding-top: 4px;
    margin: 0px;
    z-index: 9;
    background-color: #DDDDDD;
  }

  #content {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    padding: 0px;
    background-color: #EEEEEE;
  }
</style>
