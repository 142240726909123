exports.pages = [
   { 
      path: '/', 
      label: 'Trang chủ',
      childs: [
         { path: '/dash/home', label: 'Tổng quan' },
         { path: '/dash/nhiet-do', label: 'Nhiệt độ'},
         { path: '/dash/xe-gach', label: 'Xe gạch' },
         { path: '/dash/bien-tan', label: 'Biến tần' },
         { path: '/dash/dien-nang', label: 'Điện năng' },
      ]
   },
   { 
      path: '/lichsu', 
      label: 'Lịch sử', 
      childs: [
         { path: '/lichsu/block-data', label: 'Lịch sử dữ liệu' },
         { path: '/lichsu/event', label: 'Lịch sử cảnh báo' },
      ]
   },
   { 
      path: '/setting', 
      label: 'Cài đặt',
      childs: [
         { path: '/cai-dat/nhiet-do', label: 'Cài đặt nhiệt độ' },
         { path: '/cai-dat/bien-tan', label: 'Cài đặt biến tần' },
         { path: '/cai-dat/mat-khau', label: 'Đổi mật khẩu' },
         { path: '/cai-dat/quan-ly-user', label: 'Q.lý nhân viên' },
      ]
   },
]