var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{attrs:{"id":"app"}},[_c('div',{class:{
        'sys-info-bar': _vm.$vuetify.breakpoint.mdAndUp, 
        'sys-info-bar-mobile': _vm.$vuetify.breakpoint.smAndDown 
      }},[_c('point-setter',{attrs:{"point":_vm.setterPoint},model:{value:(_vm.setterDialog),callback:function ($$v) {_vm.setterDialog=$$v},expression:"setterDialog"}}),_c('pn-info-bar')],1),_c('div',{class:{
      'sys-nav-bar': _vm.$vuetify.breakpoint.mdAndUp, 
      'sys-nav-bar-mobile': _vm.$vuetify.breakpoint.smAndDown 
      }},[_c('pn-nav-bar',{attrs:{"pageTitle":_vm.activePageTitle},on:{"pageChange":_vm.gotoPath}})],1),_c('div',{class:{
      'sys-nav-bar-bottom': _vm.$vuetify.breakpoint.mdAndUp, 
      'sys-nav-bar-bottom-mobile': _vm.$vuetify.breakpoint.smAndDown 
      }},[_c('pn-navbar-bottom')],1),(_vm.kbVisible && (_vm.$root.isElectron || _vm.$root.virtualKeyboard))?_c('div',{staticClass:"pt-2 pb-2 px-4 text-center",staticStyle:{"z-index":"1000","position":"absolute","bottom":"0","left":"0","right":"0","background-color":"#DDDDDD"}},[_c('vue-touch-keyboard',{staticStyle:{"margin":"auto"},style:({'max-width': _vm.kbLayout=='numeric'?'800px':'none'}),attrs:{"id":"keyboard","options":_vm.kbOptions,"layout":_vm.kbLayout,"cancel":_vm.kbHide,"accept":_vm.kbAccept,"next":_vm.kbNext,"input":_vm.kbInput}})],1):_vm._e(),_c('div',{attrs:{"id":"content"}},[_c('v-content',{staticStyle:{"width":"100%","height":"100%"}},[_c('router-view')],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }