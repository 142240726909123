exports.content = 
   `
   <div class="d-flex align-center justify-center pa-0" style="background-color:#FFFFFF;">
     <img style="width:100%;max-width:500px;" src='https://file.ponotek.com/logach/logach_header.png'/>
   </div>
   `
   /*
   `
      <div class="pa-2 py-1" 
         style="background-color:#FFFFFF;font-size:18px;line-height:1.2;font-weight:600;color:blue;border-radius: 4px;">
         <div class="px-2 py-0" style="text-align:center;">
            HỆ THỐNG GIÁM SÁT VÀ ĐIỀU KHIỂN THIẾT BỊ QUA MẠNG INTERNET
         </div>
         <div class="px-2 py-0" style="text-align:center;">
         NHÀ MÁY GẠCH TUYNEL NINH HÒA – XÃ ĐIỆN TIẾN – TX. ĐIỆN BÀN – TỈNH QUẢNG NAM
         </div>
      </div>
   `
   */