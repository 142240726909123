exports.widgets = [
   {
      widget_type: 'point-widget',
      reportName: 'accuCounterOut',
      displayName: 'Số xe ra lò sấy',
      icon: 'mdi-wheel-barrow',
      value: 0,
      color: '#FF0000',
      unit: 'xe',
      mobileCols: 6,
      laptopCols: 6,
      valueFontSize: "48px",
      valueFontSizeMobile: '48px',
      minHeight: "90px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'accuCounterIn',
      displayName: 'Số xe vào lò nung',
      icon: 'mdi-wheel-barrow',
      value: 0,
      color: '#26A69A',
      unit: 'xe',
      mobileCols: 6,
      laptopCols: 6,
      valueFontSize: "48px",
      valueFontSizeMobile: '48px',
      minHeight: "90px",
   },
   { type: 'new-line', height: "16px" },
   {
      widget_type: 'chart-widget',
      title: 'Lịch sử xe ra vào',
      chartType: 'table',
      showDownload: true,
      showSummary: true,
      dataInterval: 1,
      points: [
         {
            reportName: 'accuCounterOut', name: 'Xe ra lò sấy', accu: true, active: true,
            scale: 1, unit: 'xe', color: "#FF0000AA", backgroundColor: "#039BE588", 
         },
         {
            reportName: 'accuCounterIn', name: 'Xe vào lò nung', accu: true, active: true,
            scale: 1, unit: 'xe', color: "#26A69AAA", backgroundColor: "#26A69A88", 
         },
      ], 
      mobileCols: 12,
      laptopCols: 12,
      minHeight: "280px",
   },
   { type: 'new-line', height: "16px" },

]

exports.style = {
   backgroundImg: 'url("/imgs/bg0.jpg")',
   backgroundColor: '#AAAAAA',
   transparentColor: '#00000033',
   blurBackground: true,
}
