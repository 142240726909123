<template>
   <v-card dark flat tile 
      class="px-0 py-0"
      style="background-color:transparent;height:100%;">
      <v-card-title 
         style="height:48px;background-color:transparent;padding:2px 4px;"
         >

         <!-- clock -->
         <div v-if="$vuetify.breakpoint.mdAndUp"
            class="pl-3 pr-3"
            style="min-width:145px;color:white; font-size:18px;font-family: cursive;font-weight:600;border-right: 1px solid #DDDDDD44;">
            {{clock}}
         </div>

         <v-btn v-if="userId"
            text class="text-none px-0 pr-1"
            @click="userDialog=true"
            color="#FFFFFF" tiles
            style="min-width:40px;border-radius:0px;border-right: 1px solid #DDDDDD44">
            <v-icon 
               class="ml-2"
               style="font-size:24px;">mdi-account</v-icon>

            <span v-if="getFullName && $vuetify.breakpoint.mdAndUp" class="px-1"
               style="max-width:130px;overflow-x:hidden;font-weight:700;"> {{ getFullName }}</span>
         </v-btn>

         <v-dialog v-model="userDialog"
            persistent
            width="640">
            <user-profile @closeMe="userDialog=false"/>
         </v-dialog>

         <div v-if="1==2" style="cursor:pointer;">  <!-- User info -->
            <v-icon 
               class="ml-2"
               :color="online?'#FFFFFF':'#CCCCCC'" 
               style="font-size:24px;">mdi-account</v-icon>

            <div class="pl-1 pr-3"
               style="display:inline-block;vertical-align:middle;text-align:left;color:white; font-size:16px;border-right: 1px solid #DDDDDD44;max-width:130px;max-height:30px;;overflow-x:hidden;overflow-y:hidden;">
               <span v-if="getFullName"> {{ getFullName }}</span>
            </div>
         </div>

         <template v-if="deviceInfo">  <!-- Lan status -->
            <v-icon 
               class="ml-2"
               :color="deviceInfo.networkState&&deviceInfo.networkState.isOnline?'#69F0AE':'#EF5350'" 
               style="font-size:24px;">mdi-lan</v-icon>

            <div v-if="$vuetify.breakpoint.mdAndUp"
               class="pl-1 pr-3"
               style="color:white; font-size:16px;border-right: 1px solid #DDDDDD44;">
               {{deviceInfo.networkState?deviceInfo.networkState.localIPv4:''}}
            </div>
         </template>

         <template>  <!-- Modbus status -->
            <v-icon 
               class="ml-2"
               :color="online?'#69F0AE':'#CCCCCC'" 
               style="font-size:24px;">mdi-transit-connection-variant</v-icon>

            <div v-if="$vuetify.breakpoint.mdAndUp"
               class="pl-1 pr-3"
               style="color:white; font-size:16px;border-right: 1px solid #DDDDDD44;">
               Modbus
            </div>
         </template>

         <template>  <!-- alarm status -->
            <v-icon v-if="alarmLevel=='error'"
               class="ml-2 mr-0"
               color="red" 
               style="font-size:24px;">mdi-bell-ring</v-icon>
            <v-icon v-if="alarmLevel=='warning'"
               class="ml-2 mr-0"
               color="orange" 
               style="font-size:24px;">mdi-bell-ring</v-icon>
            <v-icon v-if="alarmLevel=='normal'||alarmLevel==''"
               class="ml-2 mr-0"
               color="#CCCCCC"
               style="font-size:24px;">mdi-bell-ring</v-icon>

            <div v-if="$vuetify.breakpoint.mdAndUp"
               class="pl-1 pr-3"
               style="color:white; font-size:16px;border-right: 1px solid #DDDDDD44;">
               Alarm
            </div>
         </template>

         <v-spacer/>

         <v-spacer/>

      </v-card-title>
   </v-card>
</template>

<script>
import {mapGetters } from "vuex";
export default {
   computed: {
      ...mapGetters(["getFullName", "getLevel", "userId", "allowControl"]),
   },
   data () {
      return {
         modbus: true,
         online: true,
         alarm: true,
         alarmLevel: 'normal',
         ipAddress: '192.168.9.109',
         clock: this.$moment().format('DD/MM/YY HH:mm'),
         oneSecTimer: null,
         userDialog: false,
         deviceInfo: {},
         socketAskKey: new Date().getTime()
      }
   },
   sockets: {
      callHTTPRequestResult: function (payload) {
         if(payload && payload.askKey == this.socketAskKey) {
            if (payload.url.includes('devDeviceInfo')) {
               // console.log(payload.result)
               this.handleApiGetReply(payload.result)
            }
         }
      }
   },
   methods: {
      async fetchDeviceInfo () {
         if (this.$root.isRemote) {
            this.callApiOverOTA(
                        "GET",
                        this.$root.apiGate + `devDeviceInfo`,
                        null)
         } else {
            try {
            let res = await this.axios.get(this.$root.apiGate + `devDeviceInfo`)
               this.handleApiGetReply(res.data)
            } catch(err) { console.log(err) }
         }
      },
      handleApiGetReply (payload) {
         this.deviceInfo = payload.content
         this.alarmLevel = this.deviceInfo.warningLevel
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
      },
   },
   async created () {
      let self = this
      await this.fetchDeviceInfo()
      this.oneSecTimer = setInterval(function() {
         self.clock = self.$moment().format('DD/MM HH:mm:ss')
         if (self.$moment().format('ss')=='00') {
            self.fetchDeviceInfo()
         }
      }, 1000)
   },
   destroyed() {
      clearInterval(this.oneSecTimer)
   },
}
</script>