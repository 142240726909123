<template>
   <v-card dark flat tile v-if="1==1 || userId"
      class="px-0 pl-0 py-0"
      style="background-color:transparent;height:100%;">
      <v-card-title 
         style="height:48px;background-color:transparent;padding:2px 4px;"
         >
         <v-btn v-if="allowBackButton"
            icon @click="prePage"
            :disabled="!enablePre">
            <v-icon style="font-size:40px;">mdi-chevron-left</v-icon>
         </v-btn>

         <v-btn v-if="1==2 && allowGoDown"
            icon @click="goDown">
            <v-icon style="font-size:30px;">mdi-arrow-down-bold</v-icon>
         </v-btn>

         <v-spacer/>

         <div style="display:inline-block;min-width:160px;height:44px;">
            <div :style="{'font-size': $vuetify.breakpoint.mdAndUp?'28px':'20px'}"
               style="height:34px;line-height:34px;font-weight:700;">
               {{pages[activePageIndex]?pages[activePageIndex].label:'Page Title'}}
            </div>
            <div style="height:8px;background-color:#FFFFFF00;">
               <template v-for="(page, index) in  pages" >
                  <div v-if="activePageIndex == index" :key="index + page.path"
                     class="activeDot">
                  </div>
                  <div v-else :key="index + page.path"
                     class="normalDot">
                  </div>
               </template>
            </div>
         </div>

         

         <v-spacer/>

         <v-btn icon @click="nextPage"
            :disabled="!enableNext">
            <v-icon style="font-size:40px;">mdi-chevron-right</v-icon>
         </v-btn>
      </v-card-title>
   </v-card>
</template>

<script>
import {mapGetters } from "vuex";
export default {
   props: ['pageTitle'],
   watch: {
      '$route.fullPath': {
        handler: function() {
           this.pages.forEach((page, pIndex) => {
              if (page.childs && page.childs.length>0) {
                 page.childs.forEach(child => {
                    if (child.path == this.$route.fullPath) {
                       this.activePageIndex = pIndex
                    }
                 })
              } else {
                 if (page.path == this.$route.fullPath) {
                    this.activePageIndex = pIndex
                 }
              }
           })
        },
        deep: true,
        immediate: true
      }
   },
   computed: {
      ...mapGetters(["userId"]),
      enableNext: function () {
         if(this.loopInfinity) return true
         return this.activePageIndex<this.pages.length-1
      },
      enablePre: function () {
         return this.activePageIndex>0
      },
      allowGoDown: function () {
         let result = false
         this.pages.forEach(page => {
            if (page.childs && page.childs.length>0) {
               page.childs.forEach(child => {
                  if (child.path == this.$route.fullPath) {
                     result = true
                  }
               })
            }
         })
         return result
      },
   },
   data () {
      return {
         activePageIndex: 0,
         pages: require('@/mkdata/pages').pages,
         allowBackButton: false,
         loopInfinity: true
      }
   },
   methods: {
      nextPage () {
         this.activePageIndex++
         if(this.loopInfinity){
            if(this.activePageIndex>=this.pages.length)
            {
               this.activePageIndex = 0
            }
         } else {
            this.activePageIndex = Math.min(this.activePageIndex, this.pages.length-1)
         }

         if (this.pages[this.activePageIndex].childs && this.pages[this.activePageIndex].childs.length>0) {
            this.$router.push({path: this.pages[this.activePageIndex].childs[0].path})
         } else {
            this.$router.push({path: this.pages[this.activePageIndex].path})
         }
         // this.emitPageChange()
      },
      prePage () {
         this.activePageIndex = Math.max(this.activePageIndex-1, 0)
         this.emitPageChange()
      },
      goDown () {
         // this.$emit('pageChange', this.pages[this.activePageIndex].path)
         console.log(this.$route.fullPath)
         
         this.pages.forEach(page => {
            let nextIndex = -1
            if (page.childs && page.childs.length>0) {
               page.childs.forEach((child, index) => {
                  if (child.path == this.$route.fullPath) {
                     nextIndex = index + 1
                     if (nextIndex>= page.childs.length) {
                        nextIndex = 0
                     }
                  }
               })
            }
            if (nextIndex >= 0) {
               console.log("Go to path " + page.childs[nextIndex].path)
               this.$router.push({path: page.childs[nextIndex].path})
            }
         })
      },
      emitPageChange () {
         // this.$emit('pageChange', this.pages[this.activePageIndex].path)
      }
   },
   created () {
      // this.nextPage()
   },
   mounted () {
      // this.nextPage()
   }
}
</script>
   
<style>
   .normalDot {
      margin: 1.5px 3px 0 3px;
      display:inline-block;
      vertical-align:top;
      border-radius: 50%;
      background-color:#888888;
      border: 2px solid #888888;
      width: 5px;
      height: 5px;
   }

   .activeDot {
      margin: 0 3px;
      display:inline-block;
      vertical-align:top;
      border-radius: 50%;
      background-color:#FFFFFF;
      border: 2px solid white;
      width: 8px;
      height: 8px;
   }
</style>