import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    props: {
      redirectPath: '/dash/home'
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    props: {
    },
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/dash/home',
    name: 'Home',
    props: {
      modal: {
        widgets: require('@/mkdata/modalTongQuan.js').widgets,
      },
      customStyle: require('@/mkdata/modalTongQuan.js').style
    },
    component: () => import('../views/StackLayoutPage.vue')
  },
  {
    path: '/dash/nhiet-do',
    name: 'NhietDo',
    props: {
      modal: {
        widgets: require('@/mkdata/modalNhietDo.js').widgets,
      },
      customStyle: require('@/mkdata/modalNhietDo.js').style
    },
    component: () => import('../views/StackLayoutPage.vue')
  },
  {
    path: '/dash/xe-gach',
    name: 'Home',
    props: {
      modal: {
        widgets: require('@/mkdata/modalXeGach.js').widgets,
      },
      customStyle: require('@/mkdata/modalXeGach.js').style
    },
    component: () => import('../views/StackLayoutPage.vue')
  },
  {
    path: '/dash/bien-tan',
    name: 'Home',
    props: {
      modal: {
        widgets: require('@/mkdata/modalBienTan.js').widgets,
      },
      customStyle: require('@/mkdata/modalBienTan.js').style
    },
    component: () => import('../views/StackLayoutPage.vue')
  },
  {
    path: '/dash/dien-nang',
    name: 'Home',
    props: {
      modal: {
        widgets: require('@/mkdata/modalDienNang.js').widgets,
      },
      customStyle: require('@/mkdata/modalDienNang.js').style
    },
    component: () => import('../views/StackLayoutPage.vue')
  },
  
  {
    path: '/lichsu/block-data',
    name: 'History',
    component: () => import('../views/History.vue')
  },
  {
    path: '/lichsu/event',
    name: 'Event',
    component: () => import('../views/Event.vue')
  },
  // {
  //   path: '/setting',
  //   name: 'Setting',
  //   component: () => import('../views/Setting.vue')
  // },
  {
    path: '/cai-dat/nhiet-do',
    name: 'Setting',
    props: {
      modal: {
        parasGroup: require('@/mkdata/paras.js').parasNhieDo
      },
      // customStyle: require('@/mkdata/homeModal.js').style
    },
    component: () => import('../views/Setting.vue')
  },
  {
    path: '/cai-dat/bien-tan',
    name: 'Setting',
    props: {
      modal: {
        parasGroup: require('@/mkdata/paras.js').parasBienTan
      },
      // customStyle: require('@/mkdata/homeModal.js').style
    },
    component: () => import('../views/Setting.vue')
  },
  {
    path: '/cai-dat/mat-khau',
    name: 'Change Password',
    props: {
    },
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/cai-dat/user-info-update',
    name: 'User Info Update',
    props: {
    },
    component: () => import('../views/UserUpdateInfo.vue')
  },
  {
    path: '/cai-dat/quan-ly-user',
    name: 'User Manager',
    props: {
    },
    component: () => import('../views/Users.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
