exports.content = 
   `
   <div class="d-flex align-center justify-center pa-0" style="background-color:#FFFFFF;">
     <img style="width:100%;max-width:540px;" src='https://file.ponotek.com/logach/logach_footer.png'/>
   </div>
   `
   /*
   `
      <div class="pa-2 py-1" 
         style="background-color:#FFFFFF;font-size:18px;line-height:1.2;font-weight:600;color:blue;border-radius: 4px;">
         <div class="px-2 py-0" style="text-align:center;">
            ĐƠN VỊ THỰC HIỆN: CÔNG TY TNHH CƠ KHÍ VÀ CHIẾU SÁNG NGUYÊN BẢO
         </div>
         <div class="px-2 py-0" style="text-align:center;">
            Địa chỉ: 124 Lý Thái Tổ - Phường Điện An – TX. Điện Bàn – Tỉnh Quảng Nam
         </div>
         <div class="px-2 py-0" style="text-align:center;">
            Email: nguyenbaokinhdoanh@gmail.com – Điện thoại: 0905.741.727
         </div>
      </div>
   `
   */